import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";

export default () => {

	const { companies = [] } = useLoaderData();

	console.log(companies)

	const colClass = "grid-cols-[30px_1fr_100px]"

	return <div className="">
		<Link to={`/new`} className="bg-black text-white px-3 py-2 mb-2 block max-w-max fixed top-5 left-5">
			Add new +
		</Link>

		<div className={classNames(
			"fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-screen-sm h-96 max-h-screen",
			// "p-6"
		)}>

			<div className="overflow-auto scrollbar h-full">

				<div className={classNames("grid gap-4 font-semibold border-b border-black pb-2 px-2 sticky top-0 bg-white", colClass)}>
					<div>ID</div>
					<div>Name</div>
				</div>

				{companies.map(({ id, name }) => {

					return <div key={id} className={classNames("grid gap-4 py-2.5 px-2 border-b border-black", colClass)}>
						<Link to={`/${id}`} className="link">
							{id}
						</Link>
						<Link to={`/${id}`} className="link">
							{name}
						</Link>
					</div>
				})}

			</div>

		</div>
	</div>
}