import React, { useEffect } from "react";
// import ReactDOM from "react-dom/client";
// import { render } from "react-dom";
import { createRoot } from 'react-dom/client';
import {
	Link,
	RouterProvider,
	createBrowserRouter,
	useLoaderData,
	useParams,
} from "react-router-dom";
import { AuthContainer, supabase } from "./supabase/client";

import Root from "./routes/root";
import ErrorPage from "./routes/error";
import "./index.scss";
import Companies from "./routes/companies";

const Company = React.lazy(() => import("./routes/company.jsx"));


const router = createBrowserRouter([
	{
		path: "/",
		handle: () => ({
			crumb: () => 'Companies',
		}),
		loader: async ({ params }) => {
			// const { data: { user = {} } } = await supabase.auth.getUser()
			// const { data: profile } = await supabase.from('user_profiles').select(`first_name, last_name, cf_logo_id, division`).eq('id', user?.id).single()
			// // user_profiles

			// return {
			// 	user: {
			// 		name: `${profile?.first_name} ${profile?.last_name}`,
			// 		// ...user,
			// 		id: user?.id,
			// 		email: user?.email,
			// 		...profile,
			// 	}
			// }
			const { data: companies } = await supabase.from('companies').select(`*`)//.eq('id', user?.id).single()

			return {
				companies
			}
		},
		element: <>
			{/* <AuthContainer> */}
				<Companies />
			{/* </AuthContainer> */}
		</>,
		errorElement: <ErrorPage />,
		children: [],
		
	},
	{
		path: "/:companyId",
		id: 'company',
		element: <React.Suspense fallback={<>Loading....</>}>
			<Company />
		</React.Suspense>,
		handle: () => ({
			crumb: () => <>Company: {useParams()?.companyId}</>,
		}),
		loader: async ({ params }) => {

			if( params?.companyId !== 'new' ) {
				const { data: company } = await supabase.from('companies').select(`*,contacts:company_contacts(*)`).eq('id', params?.companyId).single()
				return { company }
			}
			return { company: null }
		}

	},
]);


const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);