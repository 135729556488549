import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'

const supabaseId = process.env.REACT_APP_SUPABASE_ID
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const SupabaseLogin = () => (
    <Auth
        supabaseClient={supabase}
        magicLink={true}
        // providers={['google', 'magic-link']}
        // theme="dark"
    />
)

const CheckUser = ({ supabaseClient, children }) => {
    const { user } = Auth.useUser();

    if (!user) return <SupabaseLogin />

    return children;
}

export const AuthContainer = ({ children }) => {
    return (
        <Auth.UserContextProvider supabaseClient={supabase}>
            <CheckUser supabaseClient={supabase}>
                {children}
            </CheckUser>
        </Auth.UserContextProvider>
    );
};